import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

import { useI18nContext } from '@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n';

const Cookies = (props) => {
  const {
    i18nState: { lang },
    t,
  } = useI18nContext();

  const [cookies, setCookie] = useCookies(['cookie-name']);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!cookies.AcceptCookies) {
      setActive(true);
    }
  }, []);

  const onAccept = () => {
    setActive(false);
    setCookie('AcceptCookies', true, {
      path: '/',
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  };

  return (
    <>
      <div
        id="cookieNotification"
        className={`notification${active ? ' -active' : ''} shadow`}
      >
        <div className="notification__content">
          <h2 className="notification__title">{t('cookies.title')}</h2>
          <p className="notification__message">
            {t('cookies.message')}{' '}
            <a
              target="_blank"
              title="Privacy Policy"
              href={
                lang === 'en-GB'
                  ? 'https://www.gcs.ac.uk/sites/default/files/DataProtectionPolicy.pdf'
                  : 'https://www.gcs.ac.uk/sites/default/files/DataProtectionPolicy_cy.pdf'
              }
              className="notification__button -primary"
            >
              {t('cookies.learn-more')}
            </a>
          </p>
          <div className="notification__footer">
            <button
              id="js-cookie-button-accept"
              className="notification__button -primary nav-action-sm"
              onClick={onAccept}
            >
              {t('cookies.accept')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

Cookies.propTypes = {
  siteTitle: PropTypes.string,
};

Cookies.defaultProps = {
  siteTitle: ``,
};

export default Cookies;
