import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';

import Dropdown from '@accrosoft-ltd/accro-multi-level-dropdown';

import { useI18nContext } from '@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n';

const Navbar = ({ props, websiteNavData }) => {
  const {
    i18nState: { lang },
    t,
    changeLang,
  } = useI18nContext();

  const [showMobileNav, setShowMobileNav] = useState(false);

  const ReturnChildNavItems = ({ WebsiteNavData }) => {
    return (
      <>
        {WebsiteNavData && WebsiteNavData.length > 0 ? (
          WebsiteNavData.map((item, i) => (
            <Dropdown
              menuClassName="dropdown-child-nav"
              wrapperClassName="dropdown-wrapper-nav"
              buttonClassName="dropdown-parentLink link-item"
              className="dropdown-child-nav"
              isActive={true}
              title={item.title}
              onClick={() =>
                navigate(
                  item.parentSlug ? item.parentSlug + item.slug : item.slug
                )
              }
            >
              <RenderChildLinks item={item} i={i} />
            </Dropdown>
          ))
        ) : (
          <li className="loading-nav"></li>
        )}
      </>
    );
  };

  const RenderChildLinks = ({ item }) => {
    return (
      <>
        {item.children && item.children.length > 0 && (
          <>
            {item.children.map((child, i) => (
              <RenderChildLinkHTML child={child} />
            ))}
          </>
        )}
      </>
    );
  };

  const RenderChildLinkHTML = ({ child, i }) => {
    return (
      <Dropdown.Item
        className="dropdown-item link"
        key={i}
        onClick={() =>
          navigate(
            child.parentSlug ? child.parentSlug + child.slug : child.slug
          )
        }
      >
        {child.children && <span className="fas fa-angle-right"></span>}
        <div>
          <span>{child.title}</span>
        </div>

        {child.children && child.children.length > 0 && (
          <Dropdown.Submenu
            menuClassName="dropdown-submenu"
            className="dropdown-submenu"
          >
            {child.children.map((childtwo, itwo) => (
              <React.Fragment key={i + '_' + itwo}>
                <RenderChildLinkHTML child={childtwo} i={itwo} />
              </React.Fragment>
            ))}
          </Dropdown.Submenu>
        )}
      </Dropdown.Item>
    );
  };

  return (
    <div className="navbar top-links__wrapper py-2 d-flex">
      <div className="container d-flex justify-content-between top-links__wrapper__container">
        <a
          href="https://www.gcs.ac.uk/recruitment"
          className="navbar-brand d-flex"
        >
          {props.smartfeedCompanyData &&
            props.smartfeedCompanyData.CompanyName &&
            props.smartfeedCompanyData.Logo && (
              <img
                loading="lazy"
                src={props.smartfeedCompanyData.Logo}
                className="logo"
                alt={props.smartfeedCompanyData.CompanyName}
              />
            )}
        </a>
        <ul className="d-none d-lg-inline-flex mr-auto header__top-links header__top-links--job-vacancies">
          {props.siteConfig.showWebsiteNav && websiteNavData && (
            <ReturnChildNavItems WebsiteNavData={websiteNavData} />
          )}

          <li className={`${(window.location.pathname)?.includes('vacancy') ? 'hide-langauge-btn' : 'show-langauge-btn'}`}>
            <a
              className="btn-link"
              onClick={(e) => changeLang(lang === 'en-GB' ? 'cy-GB' : 'en-GB')}
              style={{ cursor: 'pointer' }}
            >
              {lang === 'en-GB' ? 'Cymraeg' : 'English'}
            </a>
          </li>
          <li>
            <a href={props.useGroupVacsLink === true ? '/' : '/'}>
              {t('navbar.job-vacancies')}
            </a>
          </li>
          <li>
            {props.siteConfig.companyCareersSite ? (
              <Link
                to={`${
                  props.useGroupVacsLink === true
                    ? '/group-vacancies/'
                    : `/vacancies/`
                }saved/`}
              >
                {t('navbar.saved-jobs')}
              </Link>
            ) : (
              <Link
                to={`/${props.apiKey}${
                  props.useGroupVacsLink === true
                    ? '/group-vacancies/'
                    : `/vacancies/`
                }saved/`}
              >
                {t('navbar.saved-jobs')}
              </Link>
            )}
          </li>
          {props.appGatewayURL && props.appGatewayURL.ApplicantGateway && (
            <li>
              <a
                href={props.appGatewayURL.ApplicantGateway}
                target="_blank"
                rel="noreferrer"
              >
                {t('navbar.applicant-gateway')}
              </a>
            </li>
          )}
          <li>
            {props.siteConfig.companyCareersSite ? (
              <Link
                to={
                  props.useGroupVacsLink === true
                    ? '/group-job-alerts/'
                    : `/job-alerts/`
                }
                className="nav-action-sm"
                role="button"
              >
                {t('navbar.get-job-alerts')}
              </Link>
            ) : (
              <Link
                to={`/${props.apiKey}${
                  props.useGroupVacsLink === true
                    ? '/group-job-alerts/'
                    : `/job-alerts/`
                }`}
                className="nav-action-sm"
                role="button"
              >
                {t('navbar.get-job-alerts')}
              </Link>
            )}
          </li>
        </ul>
        <div className="d-lg-none nav__hamburger_wrap">
          <div
            className={`nav_hamburger_wrap__inner ${
              showMobileNav === true ? 'toggled' : ''
            }`}
          >
            <div
              className="nav_hamburger_icon btn p-3"
              onClick={() => setShowMobileNav(!showMobileNav)}
              aria-hidden="true"
            >
              {!showMobileNav ? (
                <span className="fas fa-bars"></span>
              ) : (
                <span className="fas fa-times"></span>
              )}
            </div>
            <div className="nav_hamburger_wrap">
              <ul className="navigation">
                {props.siteConfig.showWebsiteNav && websiteNavData && (
                  <ReturnChildNavItems WebsiteNavData={websiteNavData} />
                )}

                <li className={`${ (window.location.pathname)?.includes('vacancy') ? 'hide-langauge-btn' : 'show-langauge-btn'}`}>
                  <div className="link-item">
                    <a
                      onClick={(e) =>
                        changeLang(lang === 'en-GB' ? 'cy-GB' : 'en-GB')
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      {lang === 'en-GB' ? 'Cymraeg' : 'English'}
                    </a>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    <a href={props.useGroupVacsLink === true ? '/' : '/'}>
                      {t('navbar.job-vacancies')}
                    </a>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    {props.siteConfig.companyCareersSite ? (
                      <Link
                        to={`${
                          props.useGroupVacsLink === true
                            ? '/group-vacancies/'
                            : `/vacancies/`
                        }saved/`}
                      >
                        {t('navbar.saved-jobs')}
                      </Link>
                    ) : (
                      <Link
                        to={`/${props.apiKey}${
                          props.useGroupVacsLink === true
                            ? '/group-vacancies/'
                            : `/vacancies/`
                        }saved/`}
                      >
                        {t('navbar.saved-jobs')}
                      </Link>
                    )}
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    {props.siteConfig.companyCareersSite ? (
                      <Link
                        to={
                          props.useGroupVacsLink === true
                            ? '/group-job-alerts/'
                            : `/job-alerts/`
                        }
                      >
                        {t('navbar.get-job-alerts')}
                      </Link>
                    ) : (
                      <Link
                        to={`/${props.apiKey}${
                          props.useGroupVacsLink === true
                            ? '/group-job-alerts/'
                            : `/job-alerts/`
                        }`}
                      >
                        {t('navbar.get-job-alerts')}
                      </Link>
                    )}
                  </div>
                </li>
                {props.appGatewayURL && props.appGatewayURL.ApplicantGateway && (
                  <li>
                    <div className="link-item">
                      <a
                        href={props.appGatewayURL.ApplicantGateway}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('navbar.applicant-gateway')}
                      </a>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
