import { Link } from 'gatsby';
import React from 'react';

import { useI18nContext } from '@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n';

const Footer = (props) => {
  const { t } = useI18nContext();

  return (
    <footer className="footer-container">
      {props.smartfeedCompanyData &&
        props.smartfeedCompanyData.CompanyName &&
        props.smartfeedCompanyData.CompanyName !== 'DefaultCompany' && (
          <div className="footer-register-wrap">
            {props.apiKey && !props.jobAlertsPage && props.displayJACTA && (
              <div className="footer-register-container container">
                <div className="vf-careers-site-spacing-cta">
                  <article aria-label="Subscribe to job alerts">
                    <div className="ctacard soft">
                      <div className="ctacard__body d-flex justify-content-center">
                        <div className="col-md-10 col-lg-6 text-center">
                          <h4 className="ctacard__body__title">
                            <span>{t('footer.cant-find-role')}</span>
                          </h4>
                          <p className="ctacard__body__summary">
                            {t('footer.get-job-alerts')}
                          </p>
                          <Link
                            to={
                              !props.siteConfig.companyCareersSite
                                ? `/${props.apiKey}${
                                    props.useGroupVacsLink === true
                                      ? '/group-job-alerts/'
                                      : `/job-alerts/`
                                  }`
                                : props.useGroupVacsLink === true
                                ? '/group-job-alerts/'
                                : `/job-alerts/`
                            }
                            className="btn--action"
                          >
                            {t('footer.subscribe-to-job-alerts')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            )}
          </div>
        )}
      <div className="footer-attribution-wrap">
        <div className="footer-attribution-container row justify-content-center">
          <div className="col-xs-6">
            {!props.siteConfig.companyCareersSite && (
              <span>
                Copyright © {new Date().getFullYear()} |{' '}
                <a
                  rel="noopener"
                  target="noopener noreferrer"
                  href={`https://www.vacancyfiller.com/?utm_source=careers%20site&utm_medium=footer&utm_campaign=${
                    props.smartfeedCompanyData &&
                    props.smartfeedCompanyData.CompanyName
                      ? props.smartfeedCompanyData.CompanyName
                      : props.apiKey
                      ? props.apiKey
                      : 'noidavailable'
                  }`}
                >
                  Powered Vacancy Filler
                </a>
              </span>
            )}
            <span className="accessibility-selectors">
              <ul>
                <li className="fontSize_Selection">
                  <button
                    className="fontSize_Selection_trigger fontSize_Selection_trigger_normal"
                    alt="Normal Font Size"
                    title="Normal Font Size"
                    onClick={() => props.setFontSize('normal')}
                  >
                    <span className="fal fa-font"></span>
                  </button>
                  <button
                    className="fontSize_Selection_trigger fontSize_Selection_trigger_medium"
                    alt="Medium Font Size"
                    title="Medium Font Size"
                    onClick={() => props.setFontSize('medium')}
                  >
                    <span className="fal fa-font"></span>
                  </button>
                  <button
                    className="fontSize_Selection_trigger fontSize_Selection_trigger_large"
                    alt="Large Font Size"
                    title="Large Font Size"
                    onClick={() => props.setFontSize('large')}
                  >
                    <span className="fal fa-font"></span>
                  </button>
                </li>
                <li className="contrast_Selection">
                  {props.colourContrast ? (
                    <button
                      className="contrast_trigger contrast_trigger_light"
                      alt="Toggle Dark Mode"
                      title="Toggle Dark Mode"
                      onClick={() => props.setColourContrast(false)}
                    >
                      <span className="fas fa-adjust"></span>
                    </button>
                  ) : (
                    <button
                      className="contrast_trigger contrast_trigger_dark"
                      onClick={() => props.setColourContrast(true)}
                    >
                      <span className="fad fa-adjust"></span>
                    </button>
                  )}
                </li>
                <span className="footer-items-separator">|</span>
              </ul>
            </span>
          </div>

          <div className="col-xs-6">
            {props.appGatewayURL && props.appGatewayURL.ApplicantGateway && (
              <>
                <a
                  href={props.appGatewayURL.ApplicantGateway}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('footer.applicant-gateway')}
                </a>
                {!props.siteConfig.companyCareersSite && <span> | </span>}
              </>
            )}
            {!props.siteConfig.companyCareersSite && (
              <>
                <a
                  href={`https://www.vacancyfiller.com/privacy-policy/?utm_source=careers%20site&utm_medium=footer&utm_campaign=${
                    props.apiKey ? props.apiKey : 'noidavailable'
                  }`}
                  rel="noopener"
                  target="noopener noreferrer"
                >
                  Vacancy Filler Privacy Policy
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
